<template>
  <CRow class="align-items-center">
    <CCol
      ><strong class="text-truncate"
        >{{ getOrdersCount() }} {{ $t("role.orders.tour.table.orders") }} ·
        {{ getTotal() }}</strong
      ><br />{{ getProductsCount() }}
      {{ $t("role.orders.tour.table.articles") }}</CCol
    >
  </CRow>
</template>

<script>
import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import {
  formatPrice,
  processProgressData,
} from "../../../../../../helpers/common";
import { mapGetters } from "vuex";
import { roundListProgressMap } from "../../../../../../config/global";

export default {
  name: "OrderRoundOrders",

  data() {
    return {
      data: {},
    };
  },

  computed: {
    ...mapGetters("connections", ["currencySymbol"]),
  },

  methods: {
    getOrdersCount() {
      return get(this.data, "count_orders");
    },

    getProductsCount() {
      return get(this.data, "count_prods");
    },

    getTotal() {
      const price = get(this.data, "tot_price", 0); // FIXME
      return formatPrice(price, this.currencySymbol).format();
    },

    progressStatus() {
      const statusMap = get(this.data, "tot_status", {});
      const itemCount = get(this.data, "count_prods", 0);
      return processProgressData(
        itemCount,
        statusMap,
        cloneDeep(roundListProgressMap(this.$i18n))
      );
    },
  },
};
</script>
